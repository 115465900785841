import React from 'react';
import Container from '../../../common/components/UI/Container';
import BannerWrapper, { BannerOverlay, Flex } from './banner.style';
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { themeGet } from '@styled-system/theme-get';

const BannerContent = styled(Flex)`
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
`

const BannerInner = styled(Flex)`
  flex-direction: column;
  margin: 0% 0% 0% 40%;
  padding: 10px;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  h1, h6 {
    text-align: left;
  }
  @media only screen and (max-width: 1440px) {
    margin: 0% 20% 0% 30%;
  }
  @media only screen and (max-width: 1360px) {
    margin: 0% 20% 0% 30%;
  }
  @media only screen and (max-width: 991px) {
    margin: 0% 10% 0% 20%;
  }
  @media only screen and (max-width: 767px) {
    margin: 0% 10% 0% 10%;
  }

`

const WelcomeText = styled.h6`
  font-size: ${themeGet('fontSizes.4', '16')}px;
  color: ${themeGet('colors.primary', '#fdd00c')};
  margin-bottom: 5px;
  text-align: left;
`

const MainText = styled.h1`
  font-size: ${themeGet('fontSizes.9', '55')}px;
  font-weight: 500;
  color: ${themeGet('colors.white', '#ffffff')};
  margin-bottom: 5px;
  margin-top: 0px;
  font-family: 'Teko', sans-serif;
  line-height: 1.1 ;
  @media only screen and (max-width: 1440px) {
    font-size: 45px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 35px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 30px;
  }
`

const BottomText = styled.h6`
  font-size: ${themeGet('fontSizes.4', '16')}px;
  font-weight: 500;
  letter-spacing: -0.4em;
  color: ${themeGet('colors.white', '#ffffff')};
  margin-bottom: 15px;
  margin-top: 5px;
  letter-spacing: 0.5px;
  @media only screen and (max-width: 1440px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
`

const ChevronDownAnimated = styled.div`
  position: absolute;
  bottom: 90px;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  animation: 2s  ease-in-out 0s infinite normal none running bounce;
  @keyframes bounce {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 10px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`

const Banner = () => {
  return (
      <div>
        <div id="hero">
          <BannerWrapper>
            <BannerOverlay>
              <Flex style={{height: '100%'}}>
                <BannerContent>
                  <BannerInner>
                    <Fade bottom delay={100}>
                      <WelcomeText>
                        BENVENUTO SU PASTÉ CUSTOM & REPAIR
                      </WelcomeText>
                    </Fade>
                    <Fade bottom delay={200}>
                    <MainText>
                      SERVIZI PREMIUM DI CUSTOMIZZAZIONE, PROTEZIONE E <br/> RIPARAZIONE CARROZZERIA <br />
                    </MainText>

                    </Fade>
                    <Fade bottom delay={300}>
                      <BottomText>
                        PASTÉ CUSTOM & REPAIR è il tuo punto di riferimento a Caluso, Torino, per i servizi di customizzazione, protezione e riparazione dell'auto. Offriamo soluzioni personalizzate per la cura del tuo veicolo. Regala alla tua vettura un aspetto straordinario!
                      </BottomText>
                    </Fade>
                      <ChevronDownAnimated>
                      <svg version="1.1" id="Ebene_1" x="0px" y="0px"
                        viewBox="0 0 28.3 28.3">
                      <path fill='#fdd00c' class="st0" d="M26.9,2.6v2.3c0,0.6-0.2,1.1-0.6,1.5l-11,11c-0.6,0.6-1.6,0.6-2.3,0L2,6.4C1.6,6,1.4,5.4,1.4,4.9V2.6
                        c0-1,1.2-1.5,2-0.8l9.9,9.9c0.5,0.5,1.3,0.5,1.8,0L25,1.8C25.7,1,26.9,1.5,26.9,2.6z"/>
                      <path fill='#fdd00c' class="st0" d="M26.9,11.6v2.3c0,0.6-0.2,1.1-0.6,1.5l-11,11c-0.6,0.6-1.6,0.6-2.3,0l-11-11C1.6,15,1.4,14.5,1.4,14v-2.3
                        c0-1,1.2-1.5,2-0.8l9.9,9.9c0.5,0.5,1.3,0.5,1.8,0l9.9-9.9C25.7,10.1,26.9,10.6,26.9,11.6z"/>
                      </svg>
                      </ChevronDownAnimated>
                  </BannerInner>
                </BannerContent>
              </Flex>
            </BannerOverlay>
          </BannerWrapper>
        </div>
      </div>

  );
};

export default Banner;

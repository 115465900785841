const defWidth = 480;
const defHeight = 290;
export const images = [
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/osDX5jI.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/osDX5jI.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/240.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/240.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/m2.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/m2.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/peugeot.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/peugeot.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/range.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/range.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/arab.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/arab.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/ff.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/ff.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/rs6.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/rs6.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/sto.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/sto.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/m22.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/m22.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/cls.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/cls.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/urus.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/urus.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/r8.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/r8.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/1s.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/1s.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/500.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/500.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/s6.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/s6.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
  {
    src: "https://pastesito.s3.eu-central-1.amazonaws.com/8s.jpeg",
    original: "https://pastesito.s3.eu-central-1.amazonaws.com/8s.jpeg",
    width: defWidth,
    height: defHeight,
    caption: "Instagram post",
  },
];